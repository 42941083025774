import React, { Component } from "react";
import "./ProjectCardStyle.css";
import {useNavigate} from "react-router-dom";
import ARROW from "../media/back-arrow.png";
import Skill from "./Skill";

class ProjectCardRawHtml extends Component{
    
    handleClick = () => {
        console.log(this.props.projectID);
        this.props.navigate("/project", {state:{projectID: this.props.projectID, skills: this.props.skills}});
    }
    
    render(){
        return <div style={{width:"300px",height:"100px",backgroundColor:"blue",margin:"auto"}}>
            <div dangerouslySetInnerHTML={{ __html: this.props.htmlContent }} />;
        </div>;
    }
}


export default function(props) {
    const navigate = useNavigate();
    const htmlContent = "<h1 style='margin:auto;display:block;width:100%;text-align:center;'>Zander</h1>";
    return <ProjectCardRawHtml {...props} htmlContent={htmlContent} navigate={navigate} />;
}
