import React, { Component} from "react";
import "./NewestProjectStyle.css";
import BOTTOM_BLOB from "../media/bottom_blob.svg";
import ProjectCard from "./ProjectCard";

import {db} from '../firebase/Firebasedata';
import {collection, getDocs, orderBy,query,where} from 'firebase/firestore';
import ProjectCardRawHtml from "./ProjectCardRawHtml";
import ProjectSelector from "./ProjectSelector";

import { useState } from "react";

class NewestProject extends Component{
    
    constructor(props){  
        super(props);
        this.state = {
            loadedProjects: false,
            //projects: [],
            skills:[],
            tabSelected: 0,
        };
    }  

    async getProjects(){

        this.props.setProjectsList([]);

        if (this.state.tabSelected == 0){
            const querySnapshot = await getDocs(query(collection(db,"Projects"),orderBy("order")));
            querySnapshot.forEach((d)=>{
                this.props.projectsList.push(d);
            });
            this.setState({loadedProjects:true});
        }else if (this.state.tabSelected == 1){
            const querySnapshot = await getDocs(query(collection(db,"Projects"),where("category","==","game")));
            querySnapshot.forEach((d)=>{
                this.props.projectsList.push(d);
            });
            this.setState({loadedProjects:true});
        }else if (this.state.tabSelected == 2){
            const querySnapshot = await getDocs(query(collection(db,"Projects"),where("category","==","software")));
            querySnapshot.forEach((d)=>{
                this.props.projectsList.push(d);
            });
            this.setState({loadedProjects:true});
        }else if (this.state.tabSelected == 3){
            // const querySnapshot = await getDocs(query(collection(db,"Projects"),where("category","==","art")));
            // querySnapshot.forEach((d)=>{
            //     this.props.projectsList.push(d);
            // });
            this.setState({loadedProjects:false});
        }
    }

    handleTabChanged=(newIndex)=>{
        this.setState({ tabSelected: newIndex },()=>{
            this.getProjects();
        });
    };

    componentDidMount () {
        this.getProjects();
    }
    
    render(){

        var grid = <div className="project-grid">
        {
            this.state.loadedProjects ? this.props.projectsList.map((project,i) => 
                <ProjectCard 
                key={i}
                name={project.data().name}
                desc={project.data().desc}
                img={project.data().cover_img}
                projectID={project.id}
                skills={project.data().skills}
                category={project.data().category}
                fadeDelay={i*0.1}/>) 
            : <div className="loading">Loading...</div>
        }
        </div>;

        var noData = <div className="project-grid">
        {
            <div className="loading">Adding projects 🎨 Come back another day...</div>
        }
        </div>; 
        
        ;

        return <div id="projects" className="second-page">
            <div className="second-page-container">
                <h2 className="project-title">Projects</h2>

                <ProjectSelector tabSelected={this.state.tabSelected} tabChangeCallback={this.handleTabChanged}/>
                
                {this.state.tabSelected != 3 ? grid : noData}
                
            </div>
        </div>;
    }
}

export default function(props){
    const [list, setList] = useState([]);
    return <NewestProject {...props} projectsList={list} setProjectsList={setList}/>;
}