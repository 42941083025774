import { Component } from "react";
import "./ProjectSelectorStyle.css";

class ProjectSelector extends Component{
    
    handleClick = (index) => {
        this.props.tabChangeCallback(index);
    }
    
    render(){

        var items = ["All","Games","Software","Art"];

        return <div>
            <div className="ProjectSelector">
                <img src="/assets/filter.png" className="filter"/>
                {items.map((item, index) => (
                    
                    index == this.props.selection ? 
                        <div key={index} className="Selected" onClick={()=>this.handleClick(index)}>
                            {item}
                        </div> 
                        : 
                        <div key={index} className="NotSelected" onClick={()=>this.handleClick(index)}>
                            {item}
                        </div>
                ))}
            </div>
        </div>
    }
}

export default function(props){
    return <ProjectSelector {...props} selection={props.tabSelected} tabChangeCallback={props.tabChangeCallback}/>
}